export const excludes = {
  admin: [
    'Deposit Manual',
    'Role',
    'Reward List',
    'Coupon List',
    'Account',
    'Product Reward',
    'Game List',
    'Product List',
    'Payment Method',
    'Shipping Method',
    'Reward Rule',
    'Coupon Type',
    'Coupon List',
    'Statistics',
    'Transactions',
    'VNPay Transaction',
    'Orders',
    'Notifications',
    'Referrals',
    'Admin',
    'Notification Listener',
    'Buy Group'
  ],
  accountant: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    // 'Customer List',
    'Statistics',
    // 'Admin',
    'Reward Rule',
    'Order List',
    'Ticket List',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Deposit Transactions',
    'Notification Listener',
    'Notify Listener',
    'Dashboard',
    'Orders',
    'Referrals',
    'Deposit Manual',
    'Big Prize Tickets'
  ],
  marketing: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    'Customer List',
    'Ticket Statistics',
    'Transactions',
    'Reward Rule',
    'Orders',
    'Reward Statistics',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Notification Listener',
    'Notify Listener',
    'Admin',
    'Operator Statistics',
    'Referrals'
  ],
  athena: [
    'Contents',
    'Configs',
    'User List',
    'Role List',
    'Customer List',
    'Ticket Statistics',
    'Transactions',
    'Reward Rule',
    'Orders',
    'Reward Statistics',
    'Coupons',
    'Notifications',
    'Reward',
    'Account',
    'Ticket',
    'Admin',
    'Notification Listener',
    'Notify Listener',
    'Operator Statistics',
    'Referrals'
  ],
  super_admin: [],
  stakeholders: [
    'Contents',
    'Configs',
    'Transactions',
    'Orders',
    'Notifications',
    'Admin',
    'Notification Listener',
    'Ticket Statistics',
    'Reward Statistics',
    'Marketing Statistics',
    'Operator Statistics',
    'Referrals'
  ],
  vipmanager: [
    'Contents',
    'Configs',
    'Statistics',
    'Transactions',
    'Orders',
    'Notifications',
    'Admin',
    'Notification Listener',
    'Invite Friends (INCOMING)',
    'Campaigns (INCOMING)',
    'Shop Accounts (INCOMING)'
  ]
};

export const ticketStatus = ['big', 'small', 'odd', 'even'];
