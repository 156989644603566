import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/views/theme/Base'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/views/pages/Dashboard.vue')
        },
        {
          path: '/builder',
          name: 'builder',
          component: () => import('@/views/pages/Builder.vue')
        },
        {
          path: '/wizard',
          name: 'wizard',
          component: () => import('@/views/pages/wizard/Wizard.vue'),
          children: [
            {
              path: 'wizard-1',
              name: 'wizard-1',
              component: () => import('@/views/pages/wizard/Wizard-1.vue')
            },
            {
              path: 'wizard-2',
              name: 'wizard-2',
              component: () => import('@/views/pages/wizard/Wizard-2.vue')
            },
            {
              path: 'wizard-3',
              name: 'wizard-3',
              component: () => import('@/views/pages/wizard/Wizard-3.vue')
            },
            {
              path: 'wizard-4',
              name: 'wizard-4',
              component: () => import('@/views/pages/wizard/Wizard-4.vue')
            }
          ]
        },
        {
          path: '/banners',
          name: 'banners',
          component: () => import('@/views/pages/banners/BannerList.vue'),
          meta: { privilege: 'banners', actions: '.' }
        },
        {
          path: '/deposit-manual',
          name: 'deposit manual',
          component: () => import('@/views/pages/accountants/DepositManual.vue'),
          meta: { privilege: 'deposit_manual', actions: '.' }
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/pages/accountants/UserList.vue'),
          meta: { privilege: 'users', actions: '.' }
        },
        {
          path: '/games',
          name: 'games',
          component: () => import('@/views/pages/games/GameList.vue'),
          meta: { privilege: 'games', actions: '.' }
        },
        {
          path: '/article-list',
          name: 'article list',
          component: () => import('@/views/pages/article/ArticleList.vue'),
          meta: { privilege: 'articles', actions: '.' }
        },
        {
          path: '/product-list',
          name: 'product list',
          component: () => import('@/views/pages/games/Product.vue'),
          meta: { privilege: 'products', actions: '.' }
        },
        {
          path: '/product-reward',
          name: 'product reward',
          component: () => import('@/views/pages/games/ProductReward.vue'),
          meta: { privilege: 'product_rewards', actions: '.' }
        },
        {
          path: '/payments',
          name: 'payments',
          component: () => import('@/views/pages/payments/PaymentList.vue'),
          meta: { privilege: 'payment_methods', actions: '.' }
        },
        {
          path: '/article-category',
          name: 'article category',
          component: () => import('@/views/pages/article/ArticleCategory.vue'),
          meta: { privilege: 'article_categories', actions: '.' }
        },
        {
          path: '/reward-rule',
          name: 'reward rule',
          component: () => import('@/views/pages/reward/RewardRuleList.vue'),
          meta: { privilege: 'reward_rules', actions: '.' }
        },
        {
          path: '/shipping',
          name: 'shipping',
          component: () => import('@/views/pages/payments/Shipping.vue'),
          meta: { privilege: 'shipping_methods', actions: '.' }
        },
        {
          path: '/deposit-transaction',
          name: 'deposit transaction',
          component: () => import('@/views/pages/transaction/DepositTransactionList.vue'),
          meta: { privilege: 'deposit_transactions', actions: '.' }
        },
        {
          path: '/orders',
          name: 'order',
          component: () => import('@/views/pages/order/Orders.vue'),
          meta: { privilege: 'orders', actions: '.' }
        },
        {
          path: '/role',
          name: 'role',
          component: () => import('@/views/pages/accountants/Roles.vue'),
          meta: { privilege: 'roles', actions: '.' }
        },
        {
          path: '/bank',
          name: 'bank',
          component: () => import('@/views/pages/bank/Banks.vue'),
          meta: { privilege: 'banks', actions: '.' }
        },
        {
          path: '/withdraw-transaction',
          name: 'withdraw transaction',
          component: () => import('@/views/pages/transaction/WithdrawTransaction.vue'),
          meta: { privilege: 'withdraw_transactions', actions: '.' }
        },
        {
          path: '/coupon-type',
          name: 'coupon type',
          component: () => import('@/views/pages/coupon-list/CouponType.vue'),
          meta: { privilege: 'coupon_types', actions: '.' }
        },
        {
          path: '/coupon',
          name: 'coupon',
          component: () => import('@/views/pages/coupon-list/Coupon.vue'),
          meta: { privilege: 'coupons', actions: '.' }
        },
        {
          path: '/screens',
          name: 'screens',
          component: () => import('@/views/pages/banners/Screens.vue'),
          meta: { privilege: 'screens', actions: '.' }
        },
        {
          path: '/rewards',
          name: 'rewards',
          component: () => import('@/views/pages/reward/Reward.vue'),
          meta: { privilege: 'rewards', actions: '.' }
        },
        {
          path: '/ticket-statistics',
          name: 'ticket-statistics',
          component: () => import('@/views/pages/payments/TicketStatistics.vue'),
          meta: { privilege: 'ticket_statistics', actions: '.' }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/views/pages/accountants/Account.vue'),
          meta: { privilege: 'customers', actions: '.' }
        },
        {
          path: '/notification-action',
          name: 'notification-action',
          component: () => import('@/views/pages/notification/NotiAction.vue'),
          meta: { privilege: 'noti_actions', actions: '.' }
        },
        {
          path: '/reward-statistics',
          name: 'reward-statistics',
          component: () => import('@/views/pages/payments/RewardStatistics.vue'),
          meta: { privilege: 'reward_statistics', actions: '.' }
        },
        {
          path: '/notification-list',
          name: 'notification-list',
          component: () => import('@/views/pages/notification/NotificationList.vue'),
          meta: { privilege: 'notifications', actions: '.' }
        },
        {
          path: '/send-notification',
          name: 'send-notification',
          component: () => import('@/views/pages/notification/SendNotification.vue'),
          meta: { privilege: 'send_noti', actions: '.' }
        },
        {
          path: '/tickets',
          name: 'tickets',
          component: () => import('@/views/pages/ticket/Tickets.vue'),
          meta: { privilege: 'tickets', actions: '.' }
        },
        {
          path: '/ticket-shops',
          name: 'ticket-shops',
          component: () => import('@/views/pages/ticket/TicketShops.vue'),
          meta: { ignoreAuth: true }
        },
        {
          path: '/ticket-rewards',
          name: 'ticket-rewards',
          component: () => import('@/views/pages/ticket/TicketRewards.vue')
        },
        {
          path: '/big-prize-tickets',
          name: 'big-prize-tickets',
          component: () => import('@/views/pages/ticket/BigPrizeTickets.vue'),
          meta: { privilege: 'big_prize', actions: '.' }
        },
        {
          path: '/marketing-statistics',
          name: 'marketing-statistics',
          component: () => import('@/views/pages/statistics/MarketingStatistics.vue'),
          meta: { privilege: 'marketing_statistics', actions: '.' }
        },
        {
          path: '/operator-statistics',
          name: 'operator-statistics',
          component: () => import('@/views/pages/statistics/OperatorStatistics.vue'),
          meta: { privilege: 'operator_statistics', actions: '.' }
        },
        {
          path: '/notify-log',
          name: 'notify-log',
          component: () => import('@/views/pages/listener/ListtenerLog.vue'),
          meta: { privilege: 'notify_log', actions: '.' }
        },
        {
          path: '/notify-log-2',
          name: 'notify-log-2',
          component: () => import('@/views/pages/listener/ListtenerLog2.vue'),
          meta: { privilege: 'notify_log_2', actions: '.' }
        },
        {
          path: '/notify-log-3',
          name: 'notify-log-3',
          component: () => import('@/views/pages/listener/ListtenerLog3.vue'),
          meta: { privilege: 'notify_log_3', actions: '.' }
        },
        {
          path: '/transaction-momo',
          name: 'transaction-momo',
          component: () => import('@/views/pages/listener/TransactionMomo.vue'),
          meta: { privilege: 'momo_transactions', actions: '.' }
        },
        {
          path: '/customer-support',
          name: 'customer-support',
          component: () => import('@/views/pages/customer_supports/CustomerSupports.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/marketing-reports',
          name: 'marketing-reports',
          component: () => import('@/views/pages/marketing_reports/MarketingReports.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/management',
          name: 'management',
          component: () => import('@/views/pages/statistics/Management.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/invite-friends',
          name: 'invite-friends',
          component: () => import('@/views/pages/referral/InviteFriends.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/campaigns',
          name: 'campaigns',
          component: () => import('@/views/pages/referral/Campaigns.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/campaign-commissions',
          name: 'campaign-commissions',
          component: () => import('@/views/pages/referral/CampaignCommissions.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/account-vips',
          name: 'account-vips',
          component: () => import('@/views/pages/referral/AccountVips.vue'),
          meta: { privilege: '*', actions: '.' }
        },
        {
          path: '/game-result',
          name: 'game-result',
          component: () => import('@/views/pages/game_result')
        }
      ]
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/views/pages/error/Error-1.vue')
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/views/pages/error/Error-2.vue')
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/views/pages/error/Error-3.vue')
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/views/pages/error/Error-4.vue')
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/views/pages/error/Error-5.vue')
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/views/pages/error/Error-6.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/views/pages/auth/Login')
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/views/pages/auth/Register')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/views/pages/error/Error-1.vue')
    },
    {
      // the 404 route, when none of the above matches
      path: '/403',
      name: '403',
      component: () => import('@/views/pages/error/Error-4.vue')
    }
  ]
});
